import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import Button from "components/Button"

import fadeAnimation from "utils/fadeAnimation"

const BanerContact = ({ withMargin }) => {
  return (
    <section
      className={`baner-contact${
        withMargin ? " baner-contact--with-margin" : ""
      }`}
    >
      <div className="container-fluid">
        <SectionHeader
          title="Masz wątpliwości,"
          content="bądź dodatkowe pytania?"
        />
        <motion.div className="baner-contact__cta" {...fadeAnimation}>
          <Button to="/kontakt/">Kontakt</Button>
        </motion.div>
      </div>
    </section>
  )
}

export default BanerContact
