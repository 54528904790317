/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"
import Bestsellers from "components/Bestsellers"

import { ContentSingle, ContentTexture } from "page_components/wallpapers"

import ConfigProvider, { useConfig } from "context/config"
import { useProducts } from "context/products"

const WallpaperContent = ({ data }) => {
  const { current_wallpaper, setCurrentWallpaper } = useConfig()

  return (
    <ContentSingle
      data={data}
      current_wallpaper={current_wallpaper}
      setCurrentWallpaper={setCurrentWallpaper}
    />
  )
}

const WallpaperContentWrapper = ({
  data,
  current_wallpaper,
  setCurrentWallpaper,
}) => {
  const { products } = useProducts()

  const formatted_products = products?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider
      variant="single-wallpaper"
      products={formatted_products}
      textures={data?.acfProduct?.textures}
    >
      <WallpaperContent
        data={data}
        current_wallpaper={current_wallpaper}
        setCurrentWallpaper={setCurrentWallpaper}
      />
    </ConfigProvider>
  )
}

const Wallpaper = ({ pageContext }) => {
  const isBrowser = typeof window !== "undefined"
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  useEffect(() => {
    if (isBrowser) {
      window.gtag("event", "view_item", {
        currency: "PLN",
        value: pageContext?.data?.data.price,
        items: [
          {
            item_id: pageContext?.data?.data?.databaseId,
            item_name: pageContext?.data?.data?.name,
            price: pageContext?.data?.data?.price,
            quantity: 1,
          },
        ],
      })
    }
  }, [])

  return (
    <Layout>
      <Breadcrumbs
        parent={{ slug: "/tapety/", title: "Tapety" }}
        title={pageContext?.data?.data?.name}
        typeWide
      />
      <WallpaperContentWrapper data={pageContext?.data?.data} />
      <ContentTexture />
      <Bestsellers />
      <BanerContact withMargin />
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const Head = ({ pageContext }) => (
  <Seo
    title={
      pageContext?.data?.data?.seo?.title
        ? pageContext?.data?.data?.seo?.title
        : pageContext?.data?.data?.name
    }
    description={
      pageContext?.data?.data?.seo?.metaDesc &&
      pageContext?.data?.data?.seo?.metaDesc
    }
  />
)

export default Wallpaper
